import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";

import userHelper from "../../helper/user";

import Login from "../Login";
import Registration from "../Registration/Registration";
import ConfirmRegistration from "../ConfirmRegistration/ConfirmRegistration";
import Wall from "../Wall";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ChangePassword from "../ChangePassword/ChangePassword";

function App() {
  const history = useHistory();

  const { searchParams } = new URL(window.location.href);
  const queryString = searchParams.toString();

  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>

          <Route
            path="/logout"
            component={() => {
              console.log(history);
              return null;
            }}
          />

          <Route exact path="/">
            <Redirect to={`/login${!!queryString ? `?${queryString}` : ""}`} />
          </Route>

          <PrivateRoute
            exact
            path="/redirect"
            component={() => {
              const redirectURL = searchParams.has("ref")
                ? searchParams.get("ref")
                : process.env.REACT_APP_APP_SELECTOR_URL;
              window.location.href = redirectURL;
              console.log(redirectURL);
              return null;
            }}
          />

          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>

          <Route path="/email-confirm">
            <ConfirmRegistration />
          </Route>

          <Route path="/reset-password">
            <ChangePassword />
          </Route>

          <Route path="/registration">
            <Registration />
          </Route>

          <Route path="*" exact={true}>
            <Redirect to={`/login${!!queryString ? `?${queryString}` : ""}`} />
          </Route>
        </Switch>
      </Router>
    </ProvideAuth>
  );
}

function ProvideAuth({ children }) {
  const [checkingUser, setCheckingUser] = useState(true);

  useEffect(() => {
    if (checkingUser === false) {
      return;
    }

    (async () => {
      setCheckingUser(false);
    })();
  }, [checkingUser]);

  return !!checkingUser ? (
    <Wall>
      <div className="spinner-border" role="status" />
    </Wall>
  ) : (
    <>{children}</>
  );
}

function PrivateRoute({ children, ...props }) {
  const [waiting, setWaiting] = useState(true);
  const history = useHistory();

  const { searchParams } = new URL(window.location.href);
  const queryString = searchParams.toString();

  useEffect(() => {
    if (waiting !== true) {
      return;
    }

    (async () => {
      try {
        await userHelper.me();
        setWaiting(false);
      } catch (error) {
        history.push(`/login${!!queryString ? `?${queryString}` : ""}`);
        console.log(error.response);
      }
    })();
  }, [waiting]);

  return !!waiting ? (
    <Wall>
      <div className="spinner-border" role="status" />
    </Wall>
  ) : (
    <Route render={() => children} {...props} />
  );
}

export default App;
